import {
  IonToolbar,
} from '@ionic/react'
import styled from 'styled-components'

const Toolbar = styled(IonToolbar)`
  display: flex;
  height: 80px;
  padding-top: 0 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  
  --background: #fff;
  --padding-start: 4px;
  --padding-end: 4px;
`

export default Toolbar
