import {
  onValue,
  ref,
  Unsubscribe,
} from 'firebase/database'
import {
  useState,
} from 'react'

import {
  getSkuPath,
} from '../helpers/utils'
import {
  database,
} from '../plugins/firebase'
import {
  Client,
} from '../services/core.type'
import {
  QueueRef,
} from '../types/queue'
import {
  SkuRef,
} from '../types/sku'

const transformSkus = (skus: any[]) : SkuRef[] => {
  return skus.map((sku) => {
    return {
      sku_id: sku.sku_id,
      is_available: sku.is_available,
      updated_at: sku.updated_at,
    }
  })
}

const useSkuRef = () => {
  const [skus, setSkus] = useState<SkuRef[]>([])

  const subscribe = (client: Client) : Promise<Unsubscribe> => {
    const path = getSkuPath(client.shopId)
    const reference = ref(database, path)

    return new Promise((resolve, reject) => {
      const unsubscribe = onValue(reference, (snapshot) => {
        const data = snapshot.val() as QueueRef
        if (data) {
          const skus = Object.values(data)
          setSkus(transformSkus(skus))
        }
      }, (error) => {
        reject(error.message)
      })
      resolve(unsubscribe)
    })
  }

  return {
    skus,
    subscribe,
  }
}

export default useSkuRef
