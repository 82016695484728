import {
  onValue,
  ref,
  Unsubscribe,
} from 'firebase/database'
import {
  useState,
} from 'react'

import {
  getStockPath,
} from '../helpers/utils'
import {
  database,
} from '../plugins/firebase'
import {
  Client,
} from '../services/core.type'
import {
  QueueRef,
} from '../types/queue'
import {
  StockRef,
} from '../types/stock'

const transformStocks = (stocks: any[]) : StockRef[] => {
  return stocks.map((stock) => {
    return {
      stock_id: stock.stock_id,
      instock: stock.instock,
      updated_at: stock.updated_at,
    }
  })
}

const useStockRef = () => {
  const [stocks, setStocks] = useState<StockRef[]>([])

  const subscribe = (client: Client) : Promise<Unsubscribe> => {
    const path = getStockPath(client.shopId)
    const reference = ref(database, path)

    return new Promise((resolve, reject) => {
      const unsubscribe = onValue(reference, (snapshot) => {
        const data = snapshot.val() as QueueRef
        if (data) {
          const stocks = Object.values(data)
          setStocks(transformStocks(stocks))
        }
      }, (error) => {
        reject(error.message)
      })
      resolve(unsubscribe)
    })
  }

  return {
    stocks,
    subscribe,
  }
}

export default useStockRef
