import {
  faTag,
} from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<{ $size: DiscountProps['size'], $disabled: boolean }>`
  display: flex;
  align-items: center;
  font-size: ${(props) => {
    if (props.$size === 'small') {
      return '11px'
    } else {
      return '13px'
    }
  }};
  font-weight: bold;
  gap: 4px;
  background: #FF4444;
  color: #fff;
  border-radius: 15px;
  padding: 0 10px;
  height: ${(props) => {
    if (props.$size === 'small') {
      return '20px'
    } else {
      return '32px'
    }
  }};
  opacity: ${(props) => props.$disabled ? .3 : 1};

  svg {
    width: 12px;
    height: 12px;
  }
`

const DiscountLabel = styled.span`

`

type DiscountProps = {
  children: React.ReactNode
  size?: 'small' | 'default'
  disabled?: boolean
}

const Discount = (props: DiscountProps) => {
  const {
    children,
    size = 'default',
    disabled = false,
  } = props

  return (
    <Wrapper
      $size={ size }
      $disabled={ disabled }
    >
      <FontAwesomeIcon icon={ faTag } />
      <DiscountLabel>
        { children }
      </DiscountLabel>
    </Wrapper>
  )
}

export default Discount
