import {
  v4 as uuidv4,
} from 'uuid'

import {
  Client,
  QRCode,
} from '../services/core.type'
import {
  CallCode,
} from '../types/call'
import {
  NotifyCode,
} from '../types/notify'
import {
  Order,
} from '../types/order'
import useCallRef from './useCallRef'
import useMyOrderRef from './useMyOrderRef'
import useNotifyRef from './useNotifyRef'

const useMain = () => {
  const { create: createOrderRef } = useMyOrderRef()
  const { create: createNotifyRef } = useNotifyRef()
  const { create: createCallRef } = useCallRef()
  const { del: deleteCallRef } = useCallRef()

  const createOrders = async (orders: Order[], clientResult: Client, qrcodeResult: QRCode) => {
    /**
     * add order
     */
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i]
      const orderId = `${order.uuid}`
      await createOrderRef(
          orderId,
          order,
          clientResult,
          qrcodeResult,
      )
    }

    /**
     * create notify
     */
    const metadata = {
      orders: orders.map((order) => (
        {
          order_id: order.uuid,
          item: order.item,
          quantity: order.quantity,
        }
      )),
    }
    if (qrcodeResult.isClientApproveOrderRequired) {
      const code: NotifyCode = 'request_approve_orders'
      await createNotifyRef(
          qrcodeResult,
          clientResult.queueNumber,
          clientResult.clientId,
          code,
          metadata,
      )
    } else {
      const code: NotifyCode = 'new_orders_created'
      await createNotifyRef(
          qrcodeResult,
          clientResult.queueNumber,
          clientResult.clientId,
          code,
          metadata,
      )
    }
  }

  const checkout = async (clientResult: Client, qrcodeResult: QRCode) => {
    const callId = uuidv4()
    const code: CallCode = 'call_checkout'
    const metadata = {
      call: {
        call_id: callId,
      },
    }
    await createNotifyRef(
        qrcodeResult,
        clientResult.queueNumber,
        clientResult.clientId,
        code,
        metadata,
    )
    await createCallRef(
        qrcodeResult,
        clientResult,
        code,
        callId,
    )
  }

  const CancelCheckout = async (clientResult: Client, qrcodeResult: QRCode) => {
    const code: CallCode = 'call_checkout'
    await deleteCallRef(
        qrcodeResult,
        clientResult,
        code,
    )
  }

  return {
    createOrders,
    checkout,
    CancelCheckout,
  }
}

export default useMain
