import React from 'react'
import {
  useSelector,
} from 'react-redux'
import styled from 'styled-components'

import {
  getNumber,
  getOrderTotal,
  isCalculatedOrder,
} from '../../helpers/utils'
import {
  MasterData,
  QRCode,
} from '../../services/core.type'
import {
  RootState,
} from '../../store'
import {
  Order,
  OrderRef,
} from '../../types/order'

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
`

export const LabelText = styled.div``

export const PriceText = styled.div``

export const TotalPriceText = styled.div`
  text-decoration: underline;
`

const NoteText = styled.div`
  color: var(--app-text-grey);
`

const StatusText = styled(NoteText)`
  font-weight: bold;
  text-decoration: none !important;
`

const EditText = styled.div`
  color: var(--app-theme-color);
`

const getTextDecoration = (orderStatus: OrderRef['status'] | undefined | null) => {
  if (orderStatus && !isCalculatedOrder(orderStatus)) {
    return 'line-through'
  }
  return ''
}

export const OrderItemWrapper = styled.div<{ $status?: OrderRef['status'] | null }>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--app-border-color);
  padding: 10px 0;

  &:last-of-type {
    border-bottom: none;
  }

  ${EditText} {
    text-decoration: ${(props) => getTextDecoration(props.$status)};
  }

  ${LabelText} {
    text-decoration: ${(props) => getTextDecoration(props.$status)};
  }

  ${PriceText} {
    text-decoration: ${(props) => getTextDecoration(props.$status)};
  }

  ${TotalPriceText} {
    text-decoration: ${(props) => getTextDecoration(props.$status)};
  }
`

export type OrderItemProps = {
  order: Order
  isEditable?: boolean
  isClientApproveOrderRequired?: QRCode['isClientApproveOrderRequired']
  onEdit?: (productId: string, uuid: string) => void
}

const OrderItem = (props: OrderItemProps) => {
  const {
    order,
    isEditable,
    isClientApproveOrderRequired,
    onEdit,
  } = props

  const app = useSelector((state: RootState) => state.app)
  const masterData = app.masterData as MasterData
  const orderTotal = getOrderTotal([order], masterData, true, true)

  return (
    <OrderItemWrapper
      $status={ order.status }
      onClick={ () => onEdit && onEdit(order.product_id, order.uuid || '') }
    >
      <Item>
        <LabelText>
          { order.item }
        </LabelText>
        <PriceText>
          { getNumber(order.price, masterData) }
        </PriceText>
      </Item>
      { order.modifiers.map((modifier, modifierIndex) => (
        modifier.options.map((option, optionIndex) => (
          <Item key={ `modifierIndex${modifierIndex}optionIndex${optionIndex}` }>
            <LabelText>
              +
              { ' ' }
              { option.name }
            </LabelText>
            <PriceText>
              { getNumber(option.price, masterData) }
            </PriceText>
          </Item>
        ))
      )) }
      { orderTotal.discount != 0 &&
      <Item>
        <LabelText>
          ส่วนลด
        </LabelText>
        <PriceText>
          { getNumber(orderTotal.discount, masterData) }
        </PriceText>
      </Item>
      }
      <Item>
        <LabelText>
          x
          { order.quantity }
        </LabelText>
        <TotalPriceText>
          { getNumber(orderTotal.preTax, masterData) }
        </TotalPriceText>
      </Item>
      { order.note &&
      <Item>
        <NoteText>
          { order.note }
        </NoteText>
      </Item>
      }
      { isClientApproveOrderRequired && order.status &&
      <Item>
        <StatusText>
          { order.status === 'waiting_for_approve' && 'กำลังดำเนินการ' }
          { order.status === 'approved' && 'ยืนยันออเดอร์แล้ว' }
          { order.status === 'rejected' && 'ยกเลิกออเดอร์แล้ว' }
          { order.status === 'deleted' && 'ยกเลิกออเดอร์แล้ว' }
        </StatusText>
      </Item>
      }
      { isEditable &&
      <Item>
        <EditText>
          แก้ไข
        </EditText>
      </Item>
      }
    </OrderItemWrapper>
  )
}

export default OrderItem
