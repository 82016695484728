import {
  faMinus,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome'
import React, {
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'

import Button from './Button'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Label = styled.div`
  font-size: 18px;
  font-weight: bold;
`

const CircleButton = styled(Button)`
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background: #D9D9D9;
  color: #B7B7B7;
  padding: 0;

  &:disabled {
    opacity: .4;
  }
`

type ButtonUnitProps = {
  defaultValue?: number
  style?: React.CSSProperties
  onChange: (value: number) => void
}

const ButtonUnit = (props: ButtonUnitProps) => {
  const {
    defaultValue,
    onChange,
    ...other
  } = props

  const [count, setCount] = useState(defaultValue != null ? defaultValue : 1)

  const handleClickMinus = () => {
    if (count > 1) {
      setCount(count - 1)
    }
  }

  const handleClickPlus = () => {
    setCount(count + 1)
  }

  useEffect(() => {
    onChange(count)
  }, [count])

  return (
    <Wrapper { ...other }>
      <CircleButton
        disabled={ count <= 1 }
        color={ 'light' }
        onClick={ handleClickMinus }
      >
        <FontAwesomeIcon icon={ faMinus } />
      </CircleButton>
      <Label>
        { count }
      </Label>
      <CircleButton
        color={ 'light' }
        onClick={ handleClickPlus }
      >
        <FontAwesomeIcon icon={ faPlus } />
      </CircleButton>
    </Wrapper>
  )
}

export default ButtonUnit
