import {
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
  IonContent,
  useIonAlert,
  useIonLoading,
} from '@ionic/react'
import React from 'react'
import {
  useSelector,
} from 'react-redux'
import {
  styled,
} from 'styled-components'

import {
  getNumber,
  getOrderTotal,
  getTaxesByOrders,
  isCalculatedOrder,
} from '../../helpers/utils'
import {
  Client,
  MasterData,
  QRCode,
} from '../../services/core.type'
import {
  RootState,
} from '../../store'
import {
  OrderRef,
} from '../../types/order'
import Button from '../build/Button'
import FixedButtonWrapper from '../build/FixedButtonWrapper'
import Header from '../build/Header'
import IconMessage from '../build/IconMessage'
import OrderList from '../combine/OrderList'
import OrderSummary from '../combine/OrderSummary'

const Wrapper = styled.div`
  padding: 0 var(--app-side-padding);
`

type MyOrderProps = {
  client: Client
  orders: OrderRef[]
  isCheckout: boolean
  isClientApproveOrderRequired?: QRCode['isClientApproveOrderRequired']
  onCheckout: (callback: (error: string | null) => void) => void
  onCancelCheckout: () => void
}

const MyOrder = (props: MyOrderProps) => {
  const {
    client,
    orders,
    isCheckout,
    isClientApproveOrderRequired,
    onCheckout,
    onCancelCheckout,
  } = props

  const [presentAlert] = useIonAlert()
  const [presentLoading, dismissLoading] = useIonLoading()
  const app = useSelector((state: RootState) => state.app)

  const handleCheckout = () => {
    presentAlert({
      header: 'ยืนยันสิ่งนี้',
      message: `ส่งคำขำเช็คชิลจากโต๊ะที่ ${client.tableNumber}`,
      buttons: [
        {
          text: 'ยกเลิก',
          role: 'cancel',
        },
        {
          text: 'ยืนยัน',
          role: 'confirm',
          handler: () => {
            presentLoading({
              message: 'กำลังส่งคำขอ',
            })

            onCheckout((error) => {
              if (!error) {
                dismissLoading()
                presentAlert({
                  header: 'ส่งคำขอเช็คบิลสำเร็จ',
                  buttons: ['ปิด'],
                })
              }
            })
          },
        },
      ],
    })
  }

  const transformedOrders = orders.map((order) => {
    return {
      ...order,
      attributes: order.attributes || [],
      discounts: order.discounts || [],
      taxes: order.taxes || [],
      modifiers: order.modifiers || [],
    }
  })

  transformedOrders.sort((a, b) => a.created_at - b.created_at)

  const masterData = app.masterData as MasterData
  const filterOrders = transformedOrders.filter((order) => isCalculatedOrder(order.status))
  const ordersTotal = getOrderTotal(filterOrders, masterData, true, true)
  const taxResults = getTaxesByOrders(filterOrders, true, masterData)

  const headerSubtitle = `โต๊ะ ${client.tableNumber} ลำดับ ${client.queueNumber}`
  const hasOrders = transformedOrders.length > 0

  return (
    <>
      <Header
        title='ออเดอร์ของฉัน'
        subtitle={ headerSubtitle }
      />
      <IonContent>
        <Wrapper>
          { !hasOrders ?
            <IconMessage
              icon={ faInfoCircle }
              message={ 'ไม่พบข้อมูล' }
            /> :
            <>
              <OrderList
                orders={ transformedOrders }
                isEditable={ false }
                isClientApproveOrderRequired={ isClientApproveOrderRequired }
              />
              <OrderSummary
                sub={ getNumber(ordersTotal.sub, masterData) }
                discount={ getNumber(ordersTotal.discount, masterData) }
                taxes={ taxResults.map((tax) => ({
                  name: tax.displayText,
                  amount: getNumber(tax.total, masterData),
                })) }
                grand={ getNumber(ordersTotal.grand, masterData) }
              />
              <FixedButtonWrapper>
                { isCheckout ?
                  <Button
                    color={ 'danger' }
                    onClick={ onCancelCheckout }
                  >
                    ยกเลิกคำขอเช็คบิล
                  </Button> :
                  <Button
                    color={ 'success' }
                    onClick={ handleCheckout }
                  >
                    ส่งคำขอเช็คบิล
                  </Button>
                }

              </FixedButtonWrapper>
            </>
          }
        </Wrapper>
      </IonContent>
    </>
  )
}

export default MyOrder
