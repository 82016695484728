import React from 'react'
import styled from 'styled-components'

import {
  getDiscountText,
} from '../../helpers/utils'
import {
  Product,
} from '../../types/product'
import Discount from '../build/Discount'
import Price from '../build/Price'

const Wrapper = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: ${(props) => props.$disabled ? '.3' : '1'};
`

const DiscountWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-flow: wrap;
`

type ProductItemPriceProps = {
  disabled?: boolean
  product: Product
}

const ProductItemPrice = (props: ProductItemPriceProps) => {
  const {
    product,
    disabled,
  } = props

  const generalDiscounts = product.discounts.filter((discount) => discount.type === 'general')

  const renderPrice = () => {
    return (
      <Price
        prices={ product.prices }
        discounts={ generalDiscounts }
      />
    )
  }

  const renderDiscounts = () => {
    return (
      <DiscountWrapper>
        { generalDiscounts.map((discount, index) => {
          return (
            <Discount
              key={ index }
              size={ 'small' }
            >
              { getDiscountText(discount.valueType, discount.valueAmount) }
            </Discount>
          )
        }) }
      </DiscountWrapper>
    )
  }

  return (
    <Wrapper $disabled={ disabled }>
      { renderPrice() }
      { renderDiscounts() }
    </Wrapper>
  )
}

export default ProductItemPrice
