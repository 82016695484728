import {
  IonCheckbox,
} from '@ionic/react'
import React from 'react'
import styled from 'styled-components'

import {
  ProductModifierOption,
} from '../../types/product'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const RadioWrapper = styled.div`
  display: flex;
  gap: 10px;
`

const OptionName = styled.div`

`

const OptionPrice = styled.div`

`

type ModifierProps = {
  modifierOption: ProductModifierOption
  isActive: boolean
  onClick: (id: string) => void
}

const Modifier = (props: ModifierProps) => {
  const {
    modifierOption,
    isActive = false,
    onClick,
  } = props

  const price = modifierOption.price

  return (
    <Wrapper onClick={ () => onClick(modifierOption.modifieroptionId) }>
      <RadioWrapper>
        <IonCheckbox
          checked={ isActive }
        />
        <OptionName>
          { modifierOption.option }
        </OptionName>
      </RadioWrapper>
      <OptionPrice>
        { price > 0 && '+' }
        { price }
      </OptionPrice>
    </Wrapper>
  )
}

export default Modifier
