import styled from 'styled-components'

import Button from './Button'

export const FIXED_BUTTON_OFFSET_BOTTOM = 30

const FixedButtonWrapper = styled.div<{ $hideButtonShadow?: boolean }>`
  width: 100%;
  position: fixed;
  bottom: ${FIXED_BUTTON_OFFSET_BOTTOM}px;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  
  ${Button} {
    box-shadow: ${ (props) => !props.$hideButtonShadow ? '0 2px 5px rgba(0, 0, 0, .3)' : '' };
  }
`

export default FixedButtonWrapper
