import React from 'react'
import styled from 'styled-components'

import {
  Item,
  LabelText,
  OrderItemWrapper,
  PriceText,
  TotalPriceText,
} from './OrderItem'

const CustomOrderItemWrapper = styled(OrderItemWrapper)`
  border-top: 1px solid var(--app-border-color);
`

export type OrderSummaryProps = {
  sub: number
  discount: number
  taxes: {
    name: string
    amount: number
  }[]
  grand: number
}

const OrderSummary = (props: OrderSummaryProps) => {
  const {
    sub,
    discount,
    taxes,
    grand,
  } = props

  return (
    <CustomOrderItemWrapper>
      <Item>
        <LabelText>
          ยอดรวม
        </LabelText>
        <PriceText>
          { sub }
        </PriceText>
      </Item>

      { discount != 0 &&
      <Item>
        <LabelText>
          ส่วนลด
        </LabelText>
        <PriceText>
          { discount }
        </PriceText>
      </Item>
      }

      { taxes.map((tax, index) => (
        <Item key={ index }>
          <LabelText>
            { tax.name }
          </LabelText>
          <PriceText>
            { tax.amount }
          </PriceText>
        </Item>
      )) }

      <Item>
        <LabelText>
          ยอดสุทธิ์
        </LabelText>
        <TotalPriceText>
          { grand }
        </TotalPriceText>
      </Item>
    </CustomOrderItemWrapper>
  )
}

export default OrderSummary
