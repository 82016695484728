import styled from 'styled-components'

type ButtonProps = {
  color?: 'success' | 'danger' | 'light'
}

const colors = {
  'success': 'var(--app-theme-color)',
  'danger': 'var(--app-danger)',
  'light': '#C8C8C8',
}

export const BUTTON_HEIGHT = 50

const Button = styled.button<ButtonProps>`
  background-color: ${(props) => colors[props.color || 'light']};
  border-radius: 15px;
  color: #fff;
  height: ${BUTTON_HEIGHT}px;
  padding: 0 20px;
  font-weight: bold;
  font-size: 15px;

  &:disabled {
    opacity: .3;
  }
`

export default Button
