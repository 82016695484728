import React, {
  useEffect,
  useRef,
} from 'react'
import styled from 'styled-components'

import {
  getCategoryOffsetY,
  isProductAvailable,
} from '../../helpers/utils'
import {
  MasterData,
  Menu,
} from '../../services/core.type'
import {
  Product as ProductType,
} from '../../types/product'
import ProductItem from './ProductItem'
import {
  DEFAULT_OTHER_MENU,
} from './Search'

export const MENU_SECTION_OFFSET = 30

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${MENU_SECTION_OFFSET}px;
  font-size: 15px;
`

const TitleSection = styled.div`
  font-weight: bold;
`

export const getCategoryClassName = (categoryId: string) => {
  return `CATEGORY_ID-${categoryId}`
}

type ProductListProps = {
  menus: Menu[]
  contentScollTop: number
  masterData: MasterData
  onScrollOverCategory: (categoryId: string) => void // เมื่อ scroll มาถึง category นั้นๆแล้ว
  onSelectProduct?: (product: ProductType) => void
}

const ProductList = (props: ProductListProps) => {
  const {
    menus,
    contentScollTop,
    masterData,
    onSelectProduct,
    onScrollOverCategory,
  } = props

  const categoryRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    categoryRefs.current.slice(0, menus.length)
  }, [])

  useEffect(() => {
    let categoryId: string | null = null
    categoryRefs.current.forEach((el, index) => {
      if (el) {
        const position = el.getBoundingClientRect()
        const resultY = position.y - getCategoryOffsetY()
        if (resultY < 0 && contentScollTop > 0) {
          categoryId = el.getAttribute('data-id')
        }
      }
    })
    if (categoryId) {
      onScrollOverCategory(categoryId)
    }
  }, [contentScollTop])

  return (
    <>
      { menus.map((menuItem, menuIndex) => (
        <Section
          ref={ (el) => {
            if (categoryRefs.current) {
              categoryRefs.current[menuIndex] = el
            }
          } }
          key={ menuIndex }
          className={ getCategoryClassName(menuItem.categoryId || DEFAULT_OTHER_MENU) }
          data-id={ menuItem.categoryId || DEFAULT_OTHER_MENU }
        >
          <TitleSection>
            { menuItem.categoryName }
          </TitleSection>
          { menuItem.products.map((productItem, productIndex) => (
            <ProductItem
              key={ productIndex }
              product={ productItem }
              disabled={ !isProductAvailable(productItem, masterData) }
              onSelectProduct={ onSelectProduct }
            />
          )) }
        </Section>
      )) }
    </>
  )
}

export default ProductList
