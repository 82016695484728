import {
  IonHeader,
} from '@ionic/react'
import React from 'react'
import styled from 'styled-components'

const BaseHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding: 0 var(--app-side-padding);
  background-color: #fff;

  .title {
    font-weight: bold;
  }

  .subtitle {
    color: #AFAFAF;
  }
`

type HeaderProps = {
  title: string
  subtitle: string
  children?: React.ReactNode
}

const Header = (props: HeaderProps) => {
  return (
    <IonHeader mode='ios'>
      <BaseHeader>
        <div className='title'>
          { props.title }
        </div>
        <div className='subtitle'>
          { props.subtitle }
        </div>
      </BaseHeader>
      { props.children }
    </IonHeader>
  )
}

export default Header
