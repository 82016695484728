import {
  faAngleLeft,
} from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome'
import {
  IonHeader,
  IonNavLink,
} from '@ionic/react'
import React from 'react'
import styled from 'styled-components'

const BaseHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 65px;
  padding: 0 var(--app-side-padding);
  background-color: #fff;

  ion-nav-link {
    display: flex;
    gap: 12px;
    height: 100%;
    align-items: center;
    font-size: 16px;

    .icon {
      display: flex;
      align-items: center;
      font-size: 18px;
    }

    .title {
      font-weight: bold;
    }
  }
`

type HeaderProps = {
  title: string
}

const HeaderBackButton = (props: HeaderProps) => {
  return (
    <IonHeader mode='ios'>
      <BaseHeader>
        <IonNavLink
          routerDirection={ 'back' }
        >
          <div className='icon'>
            <FontAwesomeIcon icon={ faAngleLeft } />
          </div>
          <div className='title'>
            { props.title }
          </div>
        </IonNavLink>
      </BaseHeader>
    </IonHeader>
  )
}

export default HeaderBackButton
