/* eslint-disable no-unused-vars */
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonSkeletonText,
  IonSpinner,
  IonTab,
  IonTabButton,
  IonTabs,
} from '@ionic/react'
import React from 'react'
import styled from 'styled-components'

import {
  IonTabBar,
} from '../integrate/Main'

const WrapperNormalLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
`

const WrapperSkeletionLoading = styled.div`
  ion-skeleton-text {
    margin: 0;
  }
`

const IonSpinnerCustom = styled(IonSpinner)`
  width: 50px;
  height: 50px;
`

export type StartupScreenProps = {
  value: number
  type?: 'normal' | 'skeleton'
}

const StartupScreen = (props: StartupScreenProps) => {
  const { type = 'normal' } = props

  if (type === 'normal') {
    return (
      <WrapperNormalLoading>
        <IonSpinnerCustom name="dots"></IonSpinnerCustom>
      </WrapperNormalLoading>
    )
  } else {
    return (
      <WrapperSkeletionLoading>
        <IonTabs>
          <IonTab tab="XXX">
            <IonHeader mode={ 'ios' }>
              <div
                style={ {
                  width: '100%',
                  height: '65px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 var(--app-side-padding)',
                  background: '#fff',
                } }
              >
                <IonSkeletonText
                  animated={ true }
                  style={ {
                    width: '100px', height: '20px',
                  } }
                />
                <IonSkeletonText
                  animated={ true }
                  style={ {
                    width: '130px', height: '20px',
                  } }
                />
              </div>
              <div
                style={ {
                  width: '100%',
                  height: '56px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0 var(--app-side-padding)',
                  gap: 10,
                  background: '#fff',
                } }
              >
                <IonSkeletonText
                  animated={ true }
                  style={ {
                    width: '66%', height: '36px',
                  } }
                />
                <IonSkeletonText
                  animated={ true }
                  style={ {
                    flex: '1', height: '36px',
                  } }
                />
              </div>
            </IonHeader>

            <IonContent
              fullscreen={ true }
              scrollY={ false }
            >
              { Array.from({
                length: 2,
              }).map((v, i) => (
                <div
                  key={ i }
                  style={ {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0 var(--app-side-padding)',
                    marginTop: '30px',
                  } }
                >
                  <div
                    style={ {
                      marginBottom: '5px',
                    } }
                  >
                    <IonSkeletonText
                      animated={ true }
                      style={ {
                        width: '140px', height: '12pxpx',
                      } }
                    />
                  </div>
                  { Array.from({
                    length: 2,
                  }).map((v, i) => (
                    <div
                      key={ i }
                      style={ {
                        display: 'flex',
                        padding: '15px 0',
                      } }
                    >
                      <div
                        style={ {
                          width: '100px',
                          height: '100px',
                          margin: '0 15px 0 0',
                        } }
                      >
                        <IonSkeletonText
                          animated={ true }
                          style={ {
                            width: '100%', height: '100%', borderRadius: '8px',
                          } }
                        />
                      </div>
                      <div
                        style={ {
                          flex: '1',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '12px',
                        } }
                      >
                        <IonSkeletonText
                          animated={ true }
                          style={ {
                            width: '130px', height: '12px', marginTop: '10px',
                          } }
                        />
                        <IonSkeletonText
                          animated={ true }
                          style={ {
                            width: '100%', height: '12px',
                          } }
                        />
                        <IonSkeletonText
                          animated={ true }
                          style={ {
                            width: '50px', height: '12px',
                          } }
                        />
                      </div>
                    </div>
                  )) }
                </div>
              )) }
            </IonContent>
          </IonTab>

          <IonTabBar
            slot="bottom"
            style={ {
              border: 'none',
            } }
          >
            { Array.from({
              length: 3,
            }).map((v, i) => (
              <IonTabButton
                tab="XXX"
                key={ i }
              >
                <div
                  style={ {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '8px',
                  } }
                >
                  <IonSkeletonText
                    animated={ true }
                    style={ {
                      width: '20px', height: '20px',
                    } }
                  />
                  <IonSkeletonText
                    animated={ true }
                    style={ {
                      width: '72px', height: '8px',
                    } }
                  />
                </div>
              </IonTabButton>
            ))
            }
          </IonTabBar>
        </IonTabs>
      </WrapperSkeletionLoading>
    )
  }
}

export default StartupScreen
