import React from 'react'
import styled from 'styled-components'

type QRCodeImageProps = {
  url: string
}

const Img = styled.img`
  width: 100%;
  max-width: 300px;
`

const QRCodeImage = (props: QRCodeImageProps) => {
  return (
    <Img src={ props.url } />
  )
}

export default QRCodeImage
