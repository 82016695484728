import React from 'react'

import Main from './components/integrate/Main'

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  )
}

export default App
