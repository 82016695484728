import type {
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  createSlice,
} from '@reduxjs/toolkit'
import {
  v4 as uuidv4,
} from 'uuid'

import {
  MasterData,
  Menu,
} from '../services/core.type'
import {
  Order,
} from '../types/order'
import {
  SkuRef,
} from '../types/sku'
import {
  StockRef,
} from '../types/stock'

export type Page = 'REQUEST_ACCESS' |
  'PICK_ORDERS' |
  'MY_ORDERS' |
  'MY_BILL' |
  'SHARE_MY_QRCODE' |
  'PAID' |
  'ERROR'

export interface AppState {
  currentPage: Page
  errMsg: string
  menus: Menu[]
  masterData: MasterData | null

  // temp orders that used for pick order page
  orders: Order[]
}

const initialState: AppState = {
  currentPage: 'PICK_ORDERS',
  errMsg: '',
  menus: [],
  masterData: null,
  orders: [],
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<Page>) => {
      state.currentPage = action.payload
    },
    setErrMsg: (state, action: PayloadAction<string>) => {
      state.errMsg = action.payload
    },
    setMenu: (state, action: PayloadAction<Menu[]>) => {
      state.menus = action.payload
    },
    updateStock: (state, action: PayloadAction<StockRef>) => {
      for (let i = 0; i < state.menus.length; i++) {
        const menu = state.menus[i]
        for (let j = 0; j < menu.products.length; j++) {
          const product = menu.products[j]
          for (let k = 0; k < product.prices.length; k++) {
            const sku = product.prices[k]
            for (let l = 0; l < sku.stocks.length; l++) {
              const stock = sku.stocks[l]
              if (stock.stockId === action.payload.stock_id) {
                state.menus[i].products[j].prices[k].stocks[l].instock = action.payload.instock
              }
            }
          }
        }
      }
    },
    updateSku: (state, action: PayloadAction<SkuRef>) => {
      for (let i = 0; i < state.menus.length; i++) {
        const menu = state.menus[i]
        for (let j = 0; j < menu.products.length; j++) {
          const product = menu.products[j]
          for (let k = 0; k < product.prices.length; k++) {
            const sku = product.prices[k]
            if (sku.skuId === action.payload.sku_id) {
              state.menus[i].products[j].prices[k].isAvailable = action.payload.is_available
            }
          }
        }
      }
    },
    setMasterData: (state, action: PayloadAction<MasterData>) => {
      state.masterData = action.payload
    },
    addOrder: (state, action: PayloadAction<Order>) => {
      state.orders = [
        ...state.orders,
        {
          ...action.payload,
          uuid: uuidv4(),
        },
      ]
    },
    editOrder: (state, action: PayloadAction<{ uuid: Order['uuid'], order: Order, }>) => {
      const orderIndex = state.orders.findIndex((order) => order.uuid === action.payload.uuid)
      if (orderIndex >= 0) {
        state.orders[orderIndex] = {
          ...action.payload.order,
          uuid: action.payload.uuid,
        }
      }
    },
    deleteOrder: (state, action: PayloadAction<{ uuid: Order['uuid'] }>) => {
      state.orders = state.orders.filter((order) => order.uuid != action.payload.uuid)
    },
    clearOrders: (state) => {
      state.orders = []
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setCurrentPage,
  setErrMsg,
  setMenu,
  updateStock,
  updateSku,
  setMasterData,
  addOrder,
  editOrder,
  deleteOrder,
  clearOrders,
} = appSlice.actions

export default appSlice.reducer
