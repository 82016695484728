import styled from 'styled-components'

const Input = styled.input`
  border: 1px solid var(--app-input-border-color);
  height: 36px;
  padding: 0 10px;
  border-radius: 6px;
  outline: none;
  width: 100%;

  &::placeholder {
    color: #E0DEDE;
  }
`

export default Input
