import {
  onValue,
  ref,
  set,
  Unsubscribe,
} from 'firebase/database'
import {
  useState,
} from 'react'

import {
  getOrderFullPath,
} from '../helpers/utils'
import {
  database,
} from '../plugins/firebase'
import {
  Client,
  QRCode,
} from '../services/core.type'
import {
  Order,
  OrderRef,
} from '../types/order'

const useMyOrderRef = () => {
  const [orders, setOrders] = useState<OrderRef[]>([])

  const create = (
      orderId: string,
      order: Order,
      clientResult: Client,
      qrcodeResult: QRCode,
  ) => {
    const payload: OrderRef = {
      queue_number: clientResult.queueNumber || '',
      table_number: clientResult.tableNumber || '',
      item: order.item,
      price: order.price,
      product_id: order.product_id,
      sku_id: order.sku_id,
      attributes: order.attributes,
      discounts: order.discounts,
      taxes: order.taxes,
      modifiers: order.modifiers,
      quantity: order.quantity,
      note: order.note,

      order_id: orderId, // use this ref key to update existing data
      client_id: clientResult.clientId as string,
      customer_id: clientResult.customerId,
      status: qrcodeResult.isClientApproveOrderRequired ? 'waiting_for_approve' : 'approved',
      created_at: new Date().getTime(),
    }

    return new Promise((resolve, reject) => {
      const path = getOrderFullPath(
      clientResult.shopId as string,
      clientResult.branchId as string,
      clientResult.psId as string,
      clientResult.queueNumber as string,
      )
      const reference = ref(database, `${path}/order_id-${orderId}`)
      return set(reference, payload)
          .then(() => {
            resolve(payload)
          })
          .catch((error) => {
            reject(error)
          })
    })
  }

  const subscribe = (client: Client) : Promise<Unsubscribe> => {
    const path = getOrderFullPath(client.shopId, client.branchId, client.psId, client.queueNumber)
    const reference = ref(database, path)

    return new Promise((resolve, reject) => {
      const unsubscribe = onValue(reference, (snapshot) => {
        const data = snapshot.val()
        let result = [] as OrderRef[]
        if (data) {
          result = Object.values(data)
        }
        setOrders(result)
      }, (error) => {
        reject(error.message)
      })
      resolve(unsubscribe)
    })
  }

  return {
    orders,
    subscribe,
    create,
  }
}

export default useMyOrderRef
