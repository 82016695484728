import React from 'react'
import styled from 'styled-components'

import LazyImage from '../build/LazyImage'

const Wrapper = styled.div`
  width: 100%;
  height: 240px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

type CoverImageProps = {
  url: string
}

const ProductCoverImage = (props: CoverImageProps) => {
  return (
    <Wrapper>
      <LazyImage src={ props.url } />
    </Wrapper>
  )
}

export default ProductCoverImage
