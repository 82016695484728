import {
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 50px 0;
`

const Icon = styled.div`
  width: 40px;
  height: 40px;
  color: var(--app-text-grey);

  svg {
    width: 100%;
    height: 100%;
  }
`

const Message = styled.div`
  font-size: 15px;
`

type IconMessageProps = {
  message: string
  icon: IconDefinition
}

const IconMessage = (props: IconMessageProps) => {
  return (
    <Wrapper>
      <Icon>
        <FontAwesomeIcon icon={ props.icon } />
      </Icon>
      <Message>
        { props.message }
      </Message>
    </Wrapper>
  )
}

export default IconMessage
