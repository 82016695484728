import {
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
  IonContent,
  IonFooter,
} from '@ionic/react'
import React from 'react'
import {
  useSelector,
} from 'react-redux'
import styled from 'styled-components'

import {
  RootState,
} from '../../store'
import {
  Order,
} from '../../types/order'
import Button from '../build/Button'
import HeaderBackButton from '../build/HeaderBackButton'
import IconMessage from '../build/IconMessage'
import Toolbar from '../build/Toolbar'
import OrderList from '../combine/OrderList'

const Wrapper = styled.div`
  padding: 0 var(--app-side-padding);
`

type ConfirmOrderProps = {
  onSelectEditOrder: (productId: string, order: Order) => void
  onSubmit: (orders: Order[]) => void
}

function ConfirmOrder(props: ConfirmOrderProps) {
  const {
    onSelectEditOrder,
    onSubmit,
  } = props

  const app = useSelector((state: RootState) => state.app)

  const hasOrders = app.orders.length > 0

  const handleEdit = (productId: string, uuid: string) => {
    const order = app.orders.find((order) => order.uuid === uuid)
    if (order) {
      onSelectEditOrder(productId, order)
    }
  }

  const handleSubmit = () => {
    onSubmit(app.orders)
  }

  return (
    <>
      <HeaderBackButton title='เลือกเมนูอาหาร' />
      <IonContent>
        <Wrapper>
          { !hasOrders ?
            <IconMessage
              icon={ faInfoCircle }
              message={ 'ไม่พบข้อมูล' }
            /> :
            <OrderList
              orders={ app.orders }
              isEditable={ true }
              onEdit={ handleEdit }
            />
          }
        </Wrapper>
      </IonContent>
      <IonFooter>
        <Toolbar>
          <Button
            color={ 'success' }
            style={ {
              width: '100%',
            } }
            disabled={ !hasOrders }
            onClick={ handleSubmit }
          >
            ยืนยัน
          </Button>
        </Toolbar>
      </IonFooter>
    </>
  )
}

export default ConfirmOrder
