import moment from 'moment'
import React, {
  useEffect,
  useState,
} from 'react'

type ExpireTimeProps = {
  date: string
}

const ExpireTime = (props: ExpireTimeProps) => {
  const [dateNow, setDateNow] = useState(Date.now())

  useEffect(() => {
    const interval = setInterval(() => {
      setDateNow(() => Date.now())
    }, 1000)
    return () => clearInterval(interval)
  }, [])


  return (
    <span>
      หมดอายุในอีก
      { ' ' }
      { moment(props.date).diff(dateNow, 'minutes') }
      { ' ' }
      นาที
    </span>
  )
}

export default ExpireTime
