import {
  IonRadioGroup,
} from '@ionic/react'
import React, {
  useEffect,
  useState,
} from 'react'
import styled, {
  CSSProperties,
} from 'styled-components'

import {
  getDiscountConditionalText,
  getDiscountText,
  getProductFullName,
  isExist,
} from '../../helpers/utils'
import {
  Product,
  ProductDiscount,
} from '../../types/product'
import Discount from '../build/Discount'
import Input from '../build/Input'
import Modifier from './Modifier'
import {
  SelectProductForm,
} from './SelectProductModal'
import SkuRadio from './Sku'

type ProductDetailProps = {
  product: Product
  usedDiscounts: ProductDiscount[] // ส่วนลดที่สามารถใช้ได้
  style?: CSSProperties
  defaultValues?: SelectProductForm
  onSkuChange: (skuId: string, productFullName: string) => void
  onModifiersChange: (modifierIds: string[]) => void
  onNoteChange: (note: string) => void
}

const Wrapper = styled.div<{ $product: Product }>`
  display: flex;
  flex-direction: column;
  padding-left: var(--app-side-padding);
  padding-right: var(--app-side-padding);
  font-size: 16px;
`

const Section = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid var(--app-border-color);

  &:last-of-type {
    border-bottom: none;
  }
`

const Name = styled.div`
  font-weight: bold;
  font-size: 20px;
`

const Title = styled.div`
  margin-bottom: 15px;
  font-weight: bold;
`

const DiscountListWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-flow: wrap;
`

const ProductDetail = (props: ProductDetailProps) => {
  const {
    product,
    usedDiscounts,
    defaultValues,
    onSkuChange,
    onModifiersChange,
    onNoteChange,
  } = props

  const [skuId, setSkuId] = useState(defaultValues?.skuId ? defaultValues.skuId : '')
  const [modifierOptionIds, setModifierOptionIds] = useState<string[]>(
    defaultValues?.modifiers ? defaultValues.modifiers.reduce((acc: string[], cur) => {
      cur.options.forEach((option) => {
        acc.push(option.option_id)
      })
      return acc
    }, []) : [],
  )
  const [note, setNote] = useState(defaultValues?.note ? defaultValues.note : '')

  const addModifierOptionId = (id: string) => {
    setModifierOptionIds([
      ...modifierOptionIds,
      id,
    ])
  }

  const removeModifierOptionId = (id: string) => {
    setModifierOptionIds(
        modifierOptionIds.filter((v) => v != id),
    )
  }

  const handleClickSku = (skuId: string) => {
    setSkuId(skuId)
  }

  const handleOnClickModifierOption = (id: string) => {
    if (!isExist(modifierOptionIds, id)) {
      addModifierOptionId(id)
    } else {
      removeModifierOptionId(id)
    }
  }

  const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value)
  }

  useEffect(() => {
    const sku = product.prices.find((price) => price.skuId === skuId)
    const productFullName = getProductFullName(product.productName, sku?.attributes || [])
    onSkuChange(skuId, productFullName)
  }, [skuId])

  useEffect(() => {
    onModifiersChange(modifierOptionIds)
  }, [modifierOptionIds])

  useEffect(() => {
    onNoteChange(note)
  }, [note])

  useEffect(() => {
    // เลือก sku ให้อัตโนมัติ ถ้ามีราคาเดียว
    if (product.prices.length === 1) {
      const sku = product.prices[0]
      setSkuId(sku.skuId)
    }
  }, [])

  const renderSelectPrices = () => {
    if (product.prices.length > 1) {
      return (
        <Section>
          <Title>
            เลือกราคา *
          </Title>
          <IonRadioGroup value={ skuId }>
            { product.prices.map((price, index) => (
              <SkuRadio
                key={ index }
                disabled={ !price.isAvailable }
                price={ price }
                discounts={ usedDiscounts }
                onClick={ handleClickSku }
              />
            )) }
          </IonRadioGroup>
        </Section>
      )
    }
  }

  const renderModifiers = () => {
    return product.modifiers.map((modifierItem, modifierIndex) => (
      <Section key={ modifierIndex }>
        <Title>
          { modifierItem.modifierName }
        </Title>
        { modifierItem.options.map((optionItem, optionIndex) => (
          <Modifier
            key={ optionIndex }
            modifierOption={ optionItem }
            isActive={ isExist(modifierOptionIds, optionItem.modifieroptionId) }
            onClick={ handleOnClickModifierOption }
          />
        )) }
      </Section>
    ))
  }

  const renderDiscounts = () => {
    if (product.discounts.length > 0) {
      const generalDiscounts = product.discounts.filter((discount) => discount.type === 'general')
      const conditionalDiscounts = product.discounts.filter((discount) => discount.type === 'conditional')

      return (
        <Section>
          <Title>
            ส่วนลด
          </Title>
          <DiscountListWrapper>
            { generalDiscounts.map((discount, index) => (
              <Discount
                size={ 'default' }
                key={ `general-index-${index}` }
              >
                { getDiscountText(discount.valueType, discount.valueAmount) }
              </Discount>
            )) }
            { conditionalDiscounts.map((discount, discountIndex) => {
              return discount.conditions.map((condition, conditionIndex) => {
                const hasDiscount = usedDiscounts.find((usedDiscount) => usedDiscount.conditionId === condition.conditionId)
                return (
                  <Discount
                    size={ 'default' }
                    key={ `conditional-index-${conditionIndex}` }
                    disabled={ !hasDiscount }
                  >
                    { getDiscountConditionalText(discount, condition) }
                  </Discount>
                )
              })
            }) }
          </DiscountListWrapper>
        </Section>
      )
    }
  }

  return (
    <Wrapper $product={ product }>
      <Section>
        <Name>
          { product.productName }
        </Name>
      </Section>
      { renderSelectPrices() }
      { renderModifiers() }
      <Section>
        <Title>
          คำขอเพิ่มเติม
        </Title>
        <Input
          placeholder={ 'เช่น ไม่เผ็ด' }
          value={ note }
          onChange={ handleNoteChange }
        />
      </Section>
      { renderDiscounts() }
    </Wrapper>
  )
}

export default ProductDetail
