import React from 'react'
import styled from 'styled-components'

import {
  getPriceText,
} from '../../helpers/utils'
import {
  ProductDiscount,
  ProductPrice,
} from '../../types/product'

const Wrapper = styled.div<{ $disabled?: boolean }>`
  display: flex;
  gap: 10px;
  
  opacity: ${(props) => props.$disabled ? '.3' : '1'};
`

const PriceText = styled.div<{ $hasDiscount: boolean }>`
  font-weight: bold;
  color: ${(props) => props.$hasDiscount ? '#FF4444' : ''};
`

const PriceTextBeforeDiscount = styled.div`
  color: #AFAFAF;
  text-decoration: line-through;
`

type PriceProps = {
  prices: ProductPrice[]
  discounts: ProductDiscount[]
  disabled?: boolean
}

const Price = (props: PriceProps) => {
  const {
    prices,
    discounts,
    disabled,
  } = props

  const priceText = getPriceText(prices, discounts)
  const priceTextBeforeDiscount = getPriceText(prices, [])
  const hasDiscount = discounts.length > 0

  return (
    <Wrapper $disabled={ disabled }>
      <PriceText $hasDiscount={ hasDiscount }>
        { priceText }
      </PriceText>
      { hasDiscount &&
        <PriceTextBeforeDiscount>
          { priceTextBeforeDiscount }
        </PriceTextBeforeDiscount>
      }
    </Wrapper>
  )
}

export default Price
