/* eslint-disable no-unused-vars */
import React from 'react'
import styled from 'styled-components'

import {
  Product,
} from '../../types/product'
import LazyImage from '../build/LazyImage'
import ProductItemPrice from './ProductItemPrice'

const ImageWrapper = styled.div`
  width: 100px;
  min-width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Name = styled.div`
  margin-top: 5px;
`

const Detail = styled.div`
  font-size: 13px;
  color: var(--app-text-grey);
`

const Status = styled.div`
  font-weight: bold;
`

const ProductWrapper = styled.div<{ $disabled?: boolean }>`
  display: flex;
  border-bottom: 1px solid var(--app-border-color);
  padding: 15px 0 15px 0;
  gap: 15px;

  &:last-of-type {
    border-bottom: none;
  }

  ${ImageWrapper} {
    opacity: ${(props) => props.$disabled ? '.3' : '1'};
  }

  ${Name} {
    opacity: ${(props) => props.$disabled ? '.3' : '1'};
  }

  ${Detail} {
    opacity: ${(props) => props.$disabled ? '.3' : '1'};
  }
`

type OrderProps = {
  product: Product
  disabled?: boolean
  onSelectProduct?: (product: Product) => void
}

const Order = (props: OrderProps) => {
  const {
    product,
    disabled,
    onSelectProduct,
  } = props

  const handleClickProduct = () => {
    if (onSelectProduct && !disabled) {
      onSelectProduct(product)
    }
  }

  return (
    <ProductWrapper
      $disabled={ disabled }
      onClick={ handleClickProduct }
    >
      { product.images.original &&
      <ImageWrapper>
        <LazyImage src={ product.images.original } />
      </ImageWrapper>
      }
      <Info>
        <Name>
          { product.productName }
        </Name>
        { /* <Detail>
          สูตรต้นตำหรับ กุ้งสดใหม่ทุกวัน น้ำข้นร้อนๆ ทานกับข้าวสวย
        </Detail> */ }
        <ProductItemPrice
          product={ product }
          disabled={ disabled }
        />
        { disabled &&
        <Status>
          สินค้าหมด
        </Status>
        }
      </Info>
    </ProductWrapper>
  )
}

export default Order
