import {
  equalTo,
  get,
  onValue,
  orderByChild,
  query,
  ref,
  remove,
  set,
  Unsubscribe,
} from 'firebase/database'
import {
  useEffect,
  useState,
} from 'react'

import {
  getCallFullPath,
} from '../helpers/utils'
import {
  database,
} from '../plugins/firebase'
import {
  Client,
  QRCode,
} from '../services/core.type'
import {
  CallCode,
  CallRef,
} from '../types/call'

const useCallRef = () => {
  const [calls, setCalls] = useState<CallRef[]>([])
  const [isCallCheckout, setIsCallCheckout] = useState<boolean>(false)
  const [isCallStaff, setIsCallStaff] = useState<boolean>(false)

  useEffect(() => {
    setIsCallCheckout(calls.some((call) => call.code === 'call_checkout'))
    setIsCallStaff(calls.some((call) => call.code === 'call_staff'))
  }, [calls])

  const subscribe = (client: Client) : Promise<Unsubscribe> => {
    const path = getCallFullPath(client.shopId, client.branchId, client.psId, client.queueNumber)
    const reference = ref(database, path)

    return new Promise((resolve, reject) => {
      const unsubscribe = onValue(reference, (snapshot) => {
        const data = snapshot.val()
        let result = [] as CallRef[]
        if (data) {
          result = Object.values(data)
        }
        setCalls(result)
      }, (error) => {
        reject(error.message)
      })
      resolve(unsubscribe)
    })
  }

  const create = (qrcode: QRCode, client: Client, code: CallCode, callId: string) => {
    return new Promise((resolve, reject) => {
      const payload : CallRef = {
        code,
      }
      const path = getCallFullPath(qrcode.shopId, qrcode.branchId, qrcode.psId, client.queueNumber)
      const reference = ref(database, `${path}/call_id-${callId}`)
      set(reference, payload)
          .then(() => {
            resolve(payload)
          })
          .catch((error) => {
            reject(error)
          })
    })
  }

  const del = (qrcode: QRCode, client: Client, code: CallCode) => {
    return new Promise((resolve, reject) => {
      const path = getCallFullPath(qrcode.shopId, qrcode.branchId, qrcode.psId, client.queueNumber)
      const reference = ref(database, path)

      const keyToCheck = 'code'
      const valueToDelete: CallCode = code
      const dataQuery = query(reference, orderByChild(keyToCheck), equalTo(valueToDelete))

      get(dataQuery)
          .then((snapshot) => {
            if (snapshot.exists()) {
              snapshot.forEach((childSnapshot) => {
                remove(childSnapshot.ref)
              })
            }
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
    })
  }

  return {
    calls,
    isCallCheckout,
    isCallStaff,
    subscribe,
    create,
    del,
  }
}

export default useCallRef
