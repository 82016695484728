import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
} from 'react'

type ModalContextProps = {
  isOpen: boolean
  content: ReactNode;
  openModal: (content: ReactNode) => void
  closeModal: () => void
  setContent: (content: JSX.Element) => void
}


const ModalContext = createContext<ModalContextProps | undefined>(undefined)

export const useModal = (): ModalContextProps => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}

export const ModalProvider= ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState<ReactNode>()

  const openModal = (modalContent: ReactNode) => {
    setContent(modalContent)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <ModalContext.Provider value={ { isOpen, content, openModal, closeModal, setContent } }>
      { children }
    </ModalContext.Provider>
  )
}
