import {
  onValue,
  ref,
  Unsubscribe,
} from 'firebase/database'
import {
  useEffect,
  useState,
} from 'react'

import {
  getQueueFullPath,
} from '../helpers/utils'
import {
  database,
} from '../plugins/firebase'
import {
  Client,
} from '../services/core.type'
import {
  QueueRef,
} from '../types/queue'

const useMyQueueRef = () => {
  const [queue, setQueue] = useState<QueueRef>()
  const [isPaid, setIsPaid] = useState<boolean>(false)

  useEffect(() => {
    setIsPaid(!!queue?.is_paid)
  }, [queue])

  const subscribe = (client: Client) : Promise<Unsubscribe> => {
    const path = getQueueFullPath(client.shopId, client.branchId, client.psId, client.queueNumber)
    const reference = ref(database, path)

    return new Promise((resolve, reject) => {
      const unsubscribe = onValue(reference, (snapshot) => {
        const data = snapshot.val() as QueueRef
        if (data) {
          setQueue(data)
        }
      }, (error) => {
        reject(error.message)
      })
      resolve(unsubscribe)
    })
  }

  return {
    queue,
    isPaid,
    subscribe,
  }
}

export default useMyQueueRef
