import liff from '@line/liff'

const LIFF_ID = `${process.env.REACT_APP_LIFF_ID}`

export interface LineUserProfile {
  userId: string;
  displayName: string;
  pictureUrl?: string;
  statusMessage?: string;
}

const useLiff = () => {
  const init = (callback: (error: string | null, profile?: LineUserProfile) => void) => {
    liff.init({
      liffId: LIFF_ID,
    }).then(async () => {
      if (!liff.isInClient()) {
        callback('init liff error (is not liff client)')
      } else {
        const profile = await liff.getProfile()
        callback(null, profile)
      }
    }).catch((err) => {
      callback(`init liff error (${err})`)
    })
  }

  const scanQRCode = (callback: (value: string | null) => void) => {
    liff.scanCodeV2()
        .then((result) => {
          callback(result.value)
        })
        .catch((error) => {
          callback(null)
        })
  }

  const addFriend = (accountId: string) => {
    liff.openWindow({
      url: `https://line.me/R/ti/p/${accountId}`,
      external: false,
    })
  }

  return {
    init,
    scanQRCode,
    closeWindow: liff.closeWindow,
    isInClient: liff.isInClient,
    openWindow: liff.openWindow,
    addFriend,
  }
}

export default useLiff
