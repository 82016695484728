import {
  IonNav,
} from '@ionic/react'
import React, {
  createContext,
  useContext,
  useRef,
} from 'react'

type NavContextType = {
  navRef: React.RefObject<HTMLIonNavElement>;
  goBack: () => void
};

const NavContext = createContext<NavContextType | undefined>(undefined)

type NavProviderProps = {
  children: React.ReactNode
}

export const NavProvider = (props: NavProviderProps) => {
  const {
    children,
  } = props
  const navRef = useRef<HTMLIonNavElement>(null)

  const goBack = () => {
    if (navRef.current) {
      navRef.current.pop()
    }
  }

  return (
    <NavContext.Provider value={ { navRef, goBack } }>
      <IonNav
        ref={ navRef }
        root={ () => children }
      />
    </NavContext.Provider>
  )
}

export const useNav = (): NavContextType => {
  const context = useContext(NavContext)
  if (!context) {
    throw new Error('useNav must be used within an NavProvider')
  }
  return context
}
