import {
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
  AlertOptions,
  IonContent,
  IonNavLink,
  ScrollDetail,
  useIonAlert,
  useIonLoading,
} from '@ionic/react'
import React, {
  createRef,
  useEffect,
  useState,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import styled from 'styled-components'

import {
  getCategoryOffsetY,
  getProductById,
} from '../../helpers/utils'
import {
  useModal,
} from '../../hooks/useModal'
import {
  useNav,
} from '../../hooks/useNav'
import {
  Client,
  MasterData,
  Menu,
} from '../../services/core.type'
import {
  RootState,
} from '../../store'
import {
  addOrder,
  clearOrders,
  deleteOrder,
  editOrder,
  setCurrentPage,
} from '../../store/appSlice'
import {
  Order,
} from '../../types/order'
import {
  Product,
} from '../../types/product'
import Button from '../build/Button'
import FixedButtonWrapper from '../build/FixedButtonWrapper'
import Header from '../build/Header'
import IconMessage from '../build/IconMessage'
import ProductList, {
  getCategoryClassName,
} from '../combine/ProductList'
import Search from '../combine/Search'
import SelectProductModal, {
  SelectProductForm,
} from '../combine/SelectProductModal'
import ConfirmOrder from './ConfirmOrder'

type PickOrderProps = {
  client: Client
  menus: Menu[]
  onConfirmOrders: (orders: Order[], callback: (error: string | null, alertMessage: string | null) => void) => void
}

const Wrapper = styled.div`
  padding: 0 var(--app-side-padding);
`

const SCROLL_DURATION_MS = 100

const PickOrder = (props: PickOrderProps) => {
  const {
    client,
    menus,
    onConfirmOrders,
  } = props

  const [presentLoading, dismissLoading] = useIonLoading()
  const [presentAlert] = useIonAlert()
  const dispatch = useDispatch()
  const app = useSelector((state: RootState) => state.app)
  const nav = useNav()
  const contentRef = createRef<HTMLIonContentElement>()
  const [contentScollTop, setContentScrollTop] = useState(0) // current y position of content
  const [disabledUpdateContentScrollTop, setDisabledUpdateContentScrollTop] = useState(false)
  const [defaultCategoryId, setDefaultCategoryId] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [filterMenus, setFilterMenus] = useState<Menu[]>(menus)
  const [productCount, setProductCount] = useState(0)

  const {
    openModal,
    closeModal,
  } = useModal()

  const handleSelectProduct = (product: Product) => {
    openModal((
      <SelectProductModal
        formType={ 'create' }
        product={ product }
        onClose={ handleCancelProduct }
        onSubmit={ handleAddOrder }
      />
    ))
  }

  const handleCancelProduct = () => {
    setSearchValue('')
    closeModal()
  }

  const handleAddOrder = (form: SelectProductForm) => {
    const newOrder: Order = {
      queue_number: client.queueNumber,
      table_number: client.tableNumber,
      item: form.item,
      price: form.price,
      product_id: form.productId,
      sku_id: form.skuId,
      attributes: form.attributes,
      discounts: form.discounts,
      taxes: form.taxes,
      modifiers: form.modifiers,
      quantity: form.quantity,
      note: form.note || '',
    }
    dispatch(addOrder(newOrder))
    setSearchValue('')
    closeModal()
  }

  const handleSelectEditOrder = (productId: string, order: Order) => {
    const product = getProductById(productId, menus)
    if (product) {
      const defaultValues: SelectProductForm = {
        productId: product.productId,
        skuId: order.sku_id,
        item: order.item,
        price: order.price,
        attributes: order.attributes,
        discounts: order.discounts,
        modifiers: order.modifiers,
        taxes: order.taxes,
        note: order.note,
        quantity: order.quantity,
        uuid: order.uuid,
      }
      openModal((
        <SelectProductModal
          formType={ 'edit' }
          defaultValues={ defaultValues }
          product={ product }
          onDelete={ handleDeleteOrder }
          onEdit={ handleEditOrder }
        />
      ))
    }
  }

  const handleDeleteOrder = (uuid: Order['uuid']) => {
    dispatch(deleteOrder({ uuid }))
    closeModal()
  }

  const handleEditOrder = (form: SelectProductForm) => {
    const order: Order = {
      queue_number: client.queueNumber,
      table_number: client.tableNumber,
      item: form.item,
      price: form.price,
      product_id: form.productId,
      sku_id: form.skuId,
      attributes: form.attributes,
      discounts: form.discounts,
      taxes: [],
      modifiers: form.modifiers,
      quantity: form.quantity,
      note: form.note || '',
    }
    dispatch(editOrder({ order, uuid: form.uuid }))
    closeModal()
  }

  const handleSubmitOrder = (orders: Order[]) => {
    presentLoading({
      message: 'กำลังบันทึกออเดอร์',
    })

    onConfirmOrders(orders, (error, alertMessage) => {
      if (!error) {
        dismissLoading()
        dispatch(setCurrentPage('MY_ORDERS'))
        dispatch(clearOrders())
        nav.goBack()

        const alertOptions: AlertOptions = {
          header: 'บันทึกออเดอร์สำเร็จ',
          buttons: ['ปิด'],
        }
        if (alertMessage) {
          alertOptions['message'] = alertMessage
        }
        presentAlert(alertOptions)
      }
    })
  }

  const handleSelectCategory = (categoryId: string) => {
    // แก้บัค, เช็คเพื่อไม่ให้ "handleScrollOverCategory()" ทำงานหลังจากคลิกเลือกเมนู
    setDisabledUpdateContentScrollTop(true)
    setTimeout(() => {
      setDisabledUpdateContentScrollTop(false)
    }, SCROLL_DURATION_MS + 100)

    const className = getCategoryClassName(categoryId)
    scrollToElement(className)
  }

  const handleSearchProductName = (value: string) => {
    setSearchValue(value)
  }

  const handleScrollOverCategory = (categoryId: string) => {
    setDefaultCategoryId(categoryId)
  }

  const handleScroll = (e: CustomEvent<ScrollDetail>) => {
    if (!disabledUpdateContentScrollTop) {
      setContentScrollTop(e.detail.scrollTop)
    }
  }

  const scrollToElement = (className: string) => {
    if (contentRef.current) {
      const contentElement = contentRef.current
      const categoryElement = contentElement.querySelector(`.${className}`)

      if (categoryElement) {
        const position = categoryElement?.getBoundingClientRect()
        const resultY = position.y - getCategoryOffsetY()
        contentRef.current?.scrollByPoint(0, resultY, SCROLL_DURATION_MS)
      }
    }
  }

  const getMenusBySearch = (productName: string, menus: Menu[]) : Menu[] => {
    return menus.reduce((acc: Menu[], cur) => {
      const menu = cur
      const products = cur.products
          .filter((productItem) => productItem.productName.search(productName) >= 0)
      if (products.length) {
        acc.push({
          ...menu,
          products,
        })
      }
      return acc
    }, [])
  }

  useEffect(() => {
    contentRef.current?.scrollToTop(0)

    if (!searchValue) {
      setFilterMenus(menus)
    } else {
      const result = getMenusBySearch(searchValue, menus)
      setFilterMenus(result)
    }
  }, [searchValue])

  useEffect(() => {
    const productCount = filterMenus.reduce((acc, cur) => {
      acc += cur.products.length
      return acc
    }, 0)
    setProductCount(productCount)
  }, [filterMenus])

  const headerSubtitle = `โต๊ะ ${client.tableNumber} ลำดับ ${client.queueNumber}`
  const ordersCount = app.orders.reduce((acc, cur) => acc + cur.quantity, 0)

  return (
    <>
      <Header
        title='เลือกเมนูอาหาร'
        subtitle={ headerSubtitle }
      >
        <Search
          menus={ menus }
          defaultCategoryId={ defaultCategoryId }
          defaultSearchValue={ searchValue }
          onSelectCategory={ handleSelectCategory }
          onSearchProductName={ handleSearchProductName }
        />
      </Header>
      <IonContent
        ref={ contentRef }
        scrollEvents={ true }
        onIonScroll={ handleScroll }
      >
        <Wrapper>
          { productCount === 0 ?
            <IconMessage
              icon={ faInfoCircle }
              message={ 'ไม่พบข้อมูล' }
            /> :
            <ProductList
              menus={ searchValue ? filterMenus : menus }
              contentScollTop={ contentScollTop }
              masterData={ app.masterData as MasterData }
              onSelectProduct={ handleSelectProduct }
              onScrollOverCategory={ handleScrollOverCategory }
            />
          }
          { app.orders.length >= 1 &&
            <FixedButtonWrapper>
              <IonNavLink
                routerDirection="forward"
                component={ () => (
                  <ConfirmOrder
                    onSelectEditOrder={ handleSelectEditOrder }
                    onSubmit={ handleSubmitOrder }
                  />
                ) }
              >
                <Button color={ 'success' }>
                  ยืนยันออเดอร์
                  { ' ' }
                  { ordersCount }
                  { ' ' }
                  รายการ
                </Button>
              </IonNavLink>
            </FixedButtonWrapper>
          }
        </Wrapper>
      </IonContent>
    </>
  )
}

export default PickOrder
