import {
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import {
  IonSearchbar,
} from '@ionic/react'
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import {
  faIcon,
} from '../../helpers/utils'
import {
  Menu,
} from '../../services/core.type'


const BaseSearch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 0 calc(var(--app-side-padding) - 5px);
  gap: 10px;
  background-color: #fff;

  ion-searchbar {
    padding: 0;
  }

  .searchbar-search-icon {
    width: 10px;
    height: 10px;
    top: calc(50% - 5px);
    left: 15px;
  }

  .searchbar-input-container {
    height: 36px;
  }

  .searchbar-input {
    border-radius: 20px;
    padding-inline-start: 35px !important;
    font-size: 13px;
  }
`

const BaseSelect = styled.select`
  width: 100%;
  height: 36px;
  background: var(--ion-color-light);
  border: none;
  outline: none;
  text-indent: 12px;
  border-radius: 20px;
  border-right: 12px solid transparent;
  font-size: 13px;
`

export const DEFAULT_OTHER_MENU = 'OTHER_MENU'

type SearchProps = {
  menus: Menu[]
  defaultCategoryId?: string
  defaultSearchValue?: string
  onSelectCategory: (categoryId: string) => void
  onSearchProductName: (value: string) => void
}

const Search = (props: SearchProps) => {
  const {
    menus,
    defaultCategoryId,
    defaultSearchValue,
    onSelectCategory,
    onSearchProductName,
  } = props

  const initCategoryValue = (() => {
    if (defaultCategoryId != null) {
      return defaultCategoryId
    }
    return menus.length ? `${menus[0].categoryId}` : DEFAULT_OTHER_MENU
  })()

  const [categoryValue, setCategoryValue] = useState<string>(initCategoryValue)
  const [searchValue, setSearchValue] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [isDisabledFocus, setIsDisabledFocus] = useState(false)
  const ionSearchRef = useRef<HTMLIonSearchbarElement | null>(null)

  const blurSearchInput = () => {
    if (ionSearchRef.current) {
      ionSearchRef.current.querySelector('input')?.blur()
    }
  }

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setCategoryValue(value)
    onSelectCategory(value)
  }

  useEffect(() => {
    if (defaultCategoryId != categoryValue && defaultCategoryId) {
      setCategoryValue(defaultCategoryId)
    }
  }, [defaultCategoryId])

  useEffect(() => {
    if (defaultSearchValue != searchValue) {
      setSearchValue(defaultSearchValue || '')
    }
  }, [defaultSearchValue])

  return (
    <BaseSearch>
      { !isSearching &&
        <div
          style={ {
            width: '65%',
          } }
        >
          <BaseSelect
            value={ categoryValue }
            onChange={ handleCategoryChange }
          >
            { menus.map((menu, index) => {
              return (
                <option
                  key={ index }
                  value={ menu.categoryId || DEFAULT_OTHER_MENU }
                >
                  { menu.categoryName }
                </option>
              )
            }) }
          </BaseSelect>
        </div>
      }
      <div
        style={ {
          flex: 1,
        } }
      >
        <IonSearchbar
          ref={ ionSearchRef }
          mode='ios'
          color={ 'light' }
          placeholder="ค้นหา"
          searchIcon={ faIcon(faSearch) }
          value={ searchValue }
          onIonInput={ (e) => {
            onSearchProductName(e.detail.value || '')
            setSearchValue(e.detail.value || '')
          } }
          onIonFocus={ () => {
            if (!isDisabledFocus) {
              setIsSearching(true)
            }
          } }
          onIonClear={ () => {
            blurSearchInput()

            setSearchValue('')
            onSearchProductName('')
            setIsSearching(false)

            setIsDisabledFocus(true)
            setTimeout(() => {
              setIsDisabledFocus(false)
            }, 300)
          } }
          onIonBlur={ (e) => {
            if (!e.target.value) {
              setIsSearching(false)
              blurSearchInput()
            }
          } }
        />
      </div>
    </BaseSearch>
  )
}

export default Search
