import moment from 'moment'
import {
  useEffect,
  useState,
} from 'react'
import {
  useDispatch,
} from 'react-redux'

import {
  setLocalClient,
  setLocalToken,
} from '../helpers/utils'
import {
  useAuthMutation,
  useClientMutation,
  useMasterDataMutation,
  useMenuMutation,
  useQrcodeMutation,
} from '../services/core.api'
import {
  AuthPayload,
  Client,
  MasterData,
  Menu,
  QRCode,
} from '../services/core.type'
import {
  setMasterData,
  setMenu,
} from '../store/appSlice'
import {
  LineUserProfile,
} from './useLiff'

const useCore = () => {
  const dispatch = useDispatch()
  const [qrcodeMutation, qrcodeResult] = useQrcodeMutation()
  const [authMutation, authResult] = useAuthMutation()
  const [clientMutation, clientResult] = useClientMutation()
  const [menuMutation, menuResult] = useMenuMutation()
  const [masterDataMutation, masterDataResult] = useMasterDataMutation()

  const [dateNow, setDateNow] = useState(Date.now())
  const [expireInSec, setExpireInSec] = useState<number | null>(null)
  const [isExpired, setIsExpired] = useState(false)
  const isOneTimeUse = qrcodeResult.data?.qrcodeType === 'one_time_use'

  useEffect(() => {
    const interval = setInterval(() => {
      setDateNow(() => Date.now())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (isOneTimeUse) {
      const mins = moment(qrcodeResult.data?.expiredAt).diff(dateNow, 'seconds')
      setExpireInSec(mins)
    }
  }, [dateNow, qrcodeResult])

  useEffect(() => {
    if (isOneTimeUse && expireInSec != null && expireInSec <= 0) {
      setIsExpired(true)
    }
  }, [expireInSec, qrcodeResult])

  const getQRCode = (qrcodeRefId: string, dateCode: string) : Promise<QRCode> => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await qrcodeMutation({
          body: {
            qrcodeRefId,
            dateCode,
          },
        })
        resolve(result.data as QRCode)
      } catch (error) {
        reject(error)
      }
    })
  }

  const getAuth = (
      clientId: string, qrcodeId: string, shopId: string, branchId: string, tableNumber: string,
      lineUserProfile?: LineUserProfile,
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        const payload: AuthPayload = {
          'client_id': clientId,
          'qrcode_id': qrcodeId,
          'shop_id': shopId,
          'branch_id': branchId,
          'table_number': tableNumber,
        }
        if (lineUserProfile) {
          payload['user_info'] = {
            platform: 'line',
            id: lineUserProfile.userId,
            name: lineUserProfile.displayName,
            picture: lineUserProfile.pictureUrl || '',
          }
        }
        const result = await authMutation({
          body: payload,
        })
        const accessToken = result.data?.accessToken as string
        setLocalToken(accessToken)
        resolve(accessToken)
      } catch (error) {
        reject(error)
      }
    })
  }

  const getClient = () : Promise<Client> => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await clientMutation()
        setLocalClient(result.data as Client)
        resolve(result.data as Client)
      } catch (error) {
        reject(error)
      }
    })
  }

  const getMenu = () : Promise<Menu[]> => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await menuMutation()
        dispatch(setMenu(result.data as Menu[]))
        resolve(result.data as Menu[])
      } catch (error) {
        reject(error)
      }
    })
  }

  const getMasterData = () : Promise<MasterData> => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await masterDataMutation()
        dispatch(setMasterData(result.data as MasterData))
        resolve(result.data as MasterData)
      } catch (error) {
        reject(error)
      }
    })
  }

  return {
    getQRCode,
    getAuth,
    getClient,
    getMenu,
    getMasterData,
    qrcodeResult,
    authResult,
    clientResult,
    menuResult,
    masterDataResult,
    expireInSec,
    isExpired,
  }
}

export default useCore
