import {
  ref,
  set,
} from 'firebase/database'
import {
  v4 as uuidv4,
} from 'uuid'

import {
  getNotifyFullPath,
} from '../helpers/utils'
import {
  database,
} from '../plugins/firebase'
import {
  QRCode,
} from '../services/core.type'
import {
  NotifyCode,
  NotifyRef,
} from '../types/notify'

const useNotifyRef = () => {
  const create = (
      qrcode: QRCode,
      queueNumber: string,
      clientId: string,
      code: NotifyCode,
      metadata: any,
  ) => {
    return new Promise((resolve, reject) => {
      const notifyId = uuidv4()
      const payload : NotifyRef = {
        notify_id: notifyId,
        platform: 'treesoft_menu_online',
        code,
        created_at: new Date().getTime(),
        queue_number: queueNumber,
        table_number: qrcode.tableNumber,
        metadata: {
          ...metadata,
          qrcode_id: qrcode.qrcodeId,
        },
        sender: {
          id: clientId,
          type: 'client',
        },
        receiver: {
          id: null,
          type: 'staff',
        },
        is_read: false,
      }
      const path = getNotifyFullPath(qrcode.shopId, qrcode.branchId, qrcode.psId, queueNumber)
      const reference = ref(database, `${path}/notify_id-${notifyId}`)
      set(reference, payload)
          .then(() => {
            resolve(payload)
          })
          .catch((error) => {
            reject(error)
          })
    })
  }

  return {
    create,
  }
}

export default useNotifyRef

