import {
  useRef,
  useState,
} from 'react'

import {
  // eslint-disable-next-line no-unused-vars
  delay,
} from '../helpers/utils'

const useLoading = () => {
  // Set up state with initial values
  const [progress, setProgress] = useState<number>(0)
  const intervalRef = useRef<number | null>(null)

  const start = () => {
    let percentage = 0

    // Simulate loading chunks of data
    intervalRef.current = window.setInterval(() => {
      percentage += 1

      if (percentage <= 90) {
        setProgress(percentage)
      }
    }, 300)
  }

  const finish = async () => {
    setProgress(100)
    if (intervalRef.current != null) {
      clearInterval(intervalRef.current)
    }

    // await delay(1000)
    setProgress(101)
  }

  return {
    progress,
    setProgress,
    start,
    finish,
  }
}

export default useLoading
