import {
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  gap: 5px;
`

const Icon = styled.div`
  color: var(--app-danger);
  height: 52px;

  svg {
    height: 100%;
  }
`

const Message = styled.div`
  font-weight: bold;
`

type ErrorProps = {
  message: string
}

const Error = (props: ErrorProps) => {
  const { message } = props

  return (
    <Wrapper>
      <Content>
        <Icon>
          <FontAwesomeIcon icon={ faExclamationTriangle } />
        </Icon>
        <Message>
          { message }
        </Message>
      </Content>
    </Wrapper>
  )
}

export default Error
