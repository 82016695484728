import React from 'react'
import styled from 'styled-components'

import {
  QRCode,
} from '../../services/core.type'
import {
  Order,
} from '../../types/order'
import OrderItem, {
  OrderItemProps,
} from './OrderItem'

const OrderListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

type OrderListProps = {
  orders: Order[]
  isEditable?: boolean
  isClientApproveOrderRequired?: QRCode['isClientApproveOrderRequired']
  onEdit?: OrderItemProps['onEdit']
}

const OrderList = (props: OrderListProps) => {
  const {
    orders,
    isEditable,
    isClientApproveOrderRequired,
    onEdit,
  } = props

  return (
    <OrderListWrapper>
      { orders.map((order, index) => (
        <OrderItem
          key={ index }
          order={ order }
          isEditable={ isEditable }
          isClientApproveOrderRequired={ isClientApproveOrderRequired }
          onEdit={ onEdit }
        />
      )) }
    </OrderListWrapper>
  )
}

export default OrderList
