import {
  configureStore,
} from '@reduxjs/toolkit'
import {
  setupListeners,
} from '@reduxjs/toolkit/query'

import {
  coreApi,
} from '../services/core.api'
import appReducer from './appSlice'

export const store = configureStore({
  reducer: {
    [coreApi.reducerPath]: coreApi.reducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
        coreApi.middleware,
    ),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
