import {
  IonContent,
} from '@ionic/react'
import React from 'react'
import styled from 'styled-components'

import {
  Client,
  QRCode,
} from '../../services/core.type'
import ExpireTime from '../build/ExpireTime'
import Header from '../build/Header'
import QRCodeImage from '../build/QRCodeImage'

type ShareQRCodeProps = {
  client: Client
  qrcode: QRCode
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 5px;
`

const Label = styled.span`
  font-size: 15px;
`

const ShareQRCode = (props: ShareQRCodeProps) => {
  const {
    qrcode,
    client,
  } = props

  const headerSubtitle = `โต๊ะ ${client.tableNumber} ลำดับ ${client.queueNumber}`

  return (
    <>
      <Header
        title='แชร์คิวอาร์โค้ด'
        subtitle={ headerSubtitle }
      />
      <IonContent
        fullscreen={ true }
        scrollY={ false }
      >
        <Wrapper>
          <WrapperInner>

            <QRCodeImage url={ qrcode.qrcodeImage } />

            <Label>
              แชร์คิวอาร์โค้ดให้กับเพื่อน เพื่อสั่งออเดอร์ร่วมกัน
            </Label>

            { qrcode.qrcodeType === 'one_time_use' &&
            <Label
              style={ {
                color: '#AFAFAF',
              } }
            >
              <ExpireTime date={ qrcode.expiredAt } />
            </Label>
            }

          </WrapperInner>
        </Wrapper>
      </IonContent>
    </>
  )
}

export default ShareQRCode
