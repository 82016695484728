import {
  IonModal,
} from '@ionic/react'
import React from 'react'

import {
  useModal,
} from '../../hooks/useModal'

interface SharedModalProps {
  onClose?: () => void;
}

const SharedModal = ({ onClose }: SharedModalProps) => {
  const {
    isOpen,
    closeModal,
    content,
  } = useModal()

  const handleDismiss = () => {
    closeModal()
  }

  return (
    <IonModal
      isOpen={ isOpen }
      onDidDismiss={ handleDismiss }
    >
      { content }
    </IonModal>
  )
}

export default SharedModal
