import {
  IonRadio,
} from '@ionic/react'
import React from 'react'
import styled from 'styled-components'

import {
  ProductDiscount,
  ProductPrice,
} from '../../types/product'
import Price from '../build/Price'

const RadioWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const Name = styled.div`
  flex: 1;
`

const Chip = styled.div`
  background: var(--app-danger);
  color: #fff;
  font-size: 11px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  border-radius: 4px;
  height: 20px;
`

const Wrapper = styled.div<{ $disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${Name} {
    opacity: ${(props) => props.$disabled ? '.3' : '1'};
  }
`

type SkuProps = {
  price: ProductPrice
  discounts: ProductDiscount[]
  disabled?: boolean
  onClick: (skuId: string) => void
}

const Sku = (props: SkuProps) => {
  const {
    price,
    discounts,
    disabled,
    onClick,
  } = props

  const name = price.attributes
      .map((attr) => {
        return attr.option
      })
      .join(', ')

  const handleClick = () => {
    if (!disabled) {
      onClick(price.skuId)
    }
  }

  return (
    <Wrapper
      $disabled={ disabled }
      onClick={ handleClick }
    >
      <RadioWrapper>
        <IonRadio
          value={ price.skuId }
          disabled={ disabled }
        />
        <Name>
          { name }
        </Name>
        { disabled &&
          <Chip>
            สินค้าหมด
          </Chip>
        }
      </RadioWrapper>
      <Price
        disabled={ disabled }
        prices={ [price] }
        discounts={ discounts }
      />
    </Wrapper>
  )
}

export default Sku
