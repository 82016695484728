import './index.css'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

import {
  IonApp,
  setupIonicReact,
} from '@ionic/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  Provider,
} from 'react-redux'

import App from './App'
import SharedModal from './components/combine/SharedModal'
import {
  ModalProvider,
} from './hooks/useModal'
import {
  NavProvider,
} from './hooks/useNav'
import reportWebVitals from './reportWebVitals'
import {
  store,
} from './store'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

setupIonicReact()

// WARNING!!!
// do not change component structure of ionic components,
// in case that ui bugs such as modal and scrollbar

const Node = (
  <Provider store={ store }>
    <ModalProvider>
      <IonApp>
        <NavProvider>
          <App />
        </NavProvider>
        <SharedModal />
      </IonApp>
    </ModalProvider>
  </Provider>
)

root.render(Node)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
