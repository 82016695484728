import {
  IonSkeletonText,
} from '@ionic/react'
import React, {
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'

const Img = styled.img``

const IonSkeletonImage = styled(IonSkeletonText)`
  width: 100%;
  height: 100%;
`

type LazyImageProps = {
  src: string
}

const LazyImage = (props: LazyImageProps) => {
  const { src } = props
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => {
      setIsLoaded(true)
    }
  }, [])

  if (isLoaded) {
    return <Img src={ src } />
  }
  return (
    <IonSkeletonImage
      animated={ true }
    />
  )
}

export default LazyImage
