import {
  onValue,
  ref,
  set,
  Unsubscribe,
} from 'firebase/database'
import {
  useState,
} from 'react'

import {
  getClientFullPath,
  getLocalClientStatus,
} from '../helpers/utils'
import {
  database,
} from '../plugins/firebase'
import {
  ClientRef,
  ClientStatus,
} from '../types/client'

const useClientRef = () => {
  const [client, setClientRef] = useState<ClientRef>({
    clientId: '',
    clientStatus: 'waiting_for_approve',
    qrcodeId: '',
  })

  const create = (
      shopId: string, branchId: string, psId: string, clientId: string, clientStatus: ClientStatus, qrcodeId: string,
  ) : Promise<ClientRef> => {
    return new Promise((resolve, reject) => {
      const payload: ClientRef = {
        clientId,
        clientStatus,
        qrcodeId,
      }
      const path = getClientFullPath(shopId, branchId, psId, clientId)
      const reference = ref(database, path)
      set(reference, payload)
          .then(() => {
            resolve(payload)
          })
          .catch((error) => {
            reject(error)
          })
    })
  }

  const waitForRequestAccessApproved = async () => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const clientStatus = getLocalClientStatus()
        const isApproved = clientStatus === 'approved'
        if (isApproved) {
          clearInterval(interval)
          resolve(true)
        }
      }, 500)
    })
  }

  const subscribe = (shopId: string, branchId: string, psId: string, clientId: string) : Promise<Unsubscribe> => {
    const path = getClientFullPath(shopId, branchId, psId, clientId)
    const reference = ref(database, path)

    return new Promise((resolve, reject) => {
      const unsubscribe = onValue(reference, (snapshot) => {
        const data = snapshot.val() as ClientRef
        setClientRef(data)
      }, (error) => {
        reject(error.message)
      })
      resolve(unsubscribe)
    })
  }

  return {
    client,
    create,
    subscribe,
    waitForRequestAccessApproved,
  }
}

export default useClientRef
